import { createStore } from 'vuex'

//tabs相关
import tabs  from './tabs/tabs';
//客户相关
import customer from './customer/customer'
//系统设置相关
import settings from './settings/settings'
//医院相关
import hospital from './hospital/hospital';
//权限相关
import permission from './permission/permission';


const moduleB = {
    state:{
        username:"moduleeB",
    },
    getters:{
        newName(state){
            return state.username + "你好!"
        }
    }
}

// Vue.use(Vuex);

//实例化 Vuex.store
// const store  = new Vuex.Store({
//     modules:{
//         tabs
//     }
// })


export default createStore({
    // 分模块
    modules:{
        tabs,
        customer,
        settings,
        hospital,
        permission,
        moduleB
    }
})

// export default store;
