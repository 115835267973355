import axios from '../../axios/axios';
import ipAddress from '../../axios/ipAddress' //域名
import QS from 'qs'; //序列化


/**
 * 员工相关接口
 */
const staff = {

    /**
     * 员工相关
     */

    // 员工分页
    findAllByPage (page) {
        return axios.post(ipAddress.qi + '/staff/findAllByPage', page, true)
    },

    // 新增/修改 员工
    save (form) {
        return axios.post(ipAddress.qi + '/staff/save', form, true)
    },

    // 员工-详情
    findById (form) {
        return axios.post(ipAddress.qi + '/staff/findById', form, true)
    },



    /**
     * 查询全部部门
     */
    departmentFindAll(form){
        return axios.post(ipAddress.qi + '/department/findAll', form, true)
    }



}
export default staff;