import axios from '../../axios/axios';
import ipAddress from '../../axios/ipAddress' //域名
import QS from 'qs'; //序列化


/**
 * 首页相关接口
 */
const home = {

    /**
     * 首页相关
     */

    // 我的简报
    findBriefReport (form) {
        return axios.post(ipAddress.qi + '/customer/findBriefReport', form, true)
    },

    // 日历提醒
    findCalendarWarn (form) {
        return axios.post(ipAddress.qi + '/customer/findCalendarWarn', form, true)
    }

}
export default home;