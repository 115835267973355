import { createApp } from 'vue'

import App from './App.vue'
// 路由
import router from './router'
// 封装api请求
import http from '../src/api/api'

//引入vuex
import store from '../src/vuex/store'

//引入zh-cn
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// 引入icons
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// import { ElMessage } from 'element-plus'


const app = createApp(App);
app.config.globalProperties.$api = http;
app.use(router);
app.use(store)
app.mount("#app");
app.use(ElementPlus, {locale: zhCn,})
// app.use(ElMessage)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
