import axios from '../../axios/axios';
import ipAddress from '../../axios/ipAddress' //域名
import QS from 'qs'; //序列化


/**
 * 医院相关接口
 */
const hospital = {

    /**
     * 医院相关
     */

    //医院分页
    findAllByPage (page) {
        return axios.post(ipAddress.qi + '/hospital/findAllByPage', page, true)
    },

    // 医院详情
    findById(form){
        return axios.post(ipAddress.qi + '/hospital/findById', form, true)
    },

    // 新增/ 编辑 医院
    save(form){
        return axios.post(ipAddress.qi + '/hospital/save', form, true)
    },
    // 删除医院
    delById(form){
        return axios.post(ipAddress.qi + '/hospital/delById', form, true)
    },


    /**
     * 
     * 医生相关
     */
    // 医生分页
    doctorFindAllByPage(page){
        return axios.post(ipAddress.qi + '/doctor/findAllByPage', page, true)
    },

    // 新增/编辑 医生
    doctorSave(form){
        return axios.post(ipAddress.qi + '/doctor/save', form, true)
    },

    // 医生详情
    doctorInfo(form){
        return axios.post(ipAddress.qi + '/doctor/findById', form, true)
    },

    // 医生-删除
    doctorDel(form){
        return axios.post(ipAddress.qi + '/doctor/delById', form, true)
    },


    /**
     * 开展项目相关
     */
    // 项目列表
    findOpenProjectById(form){
        return axios.post(ipAddress.qi + '/hospital/findOpenProjectById', form, true)
    },

    // 选择项目分页
    projectFindAllByPage(page){
        return axios.post(ipAddress.qi + '/openProject/findAllByPage', page, true)
    },

    // 新增开展项目 
    addOpenProject(form){
        return axios.post(ipAddress.qi + '/hospital/addOpenProject', form, true)
    },


    /**
     * 新增客户 - 选择医院
     */
    findByPage(page){
        return axios.post(ipAddress.qi + '/hospital/findByPage', page, true)
    }
    
    



}
export default hospital;