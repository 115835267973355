import state from './state'
import getter from './getter'
import mutations from './mutations'
import action from './action'

export default{
    namespaced: true,
    state,
    // getter,
    mutations,
    // action
    // state:{
    //     username:"moduleeA",
    // },
    getters:{
        newName(state){
            return state.username + "你好!"
        }
    }
}