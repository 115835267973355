export default {
    recordId:null,

    // 高级查询ID
    advancedQuryId:null,
    // 字典管理ID
    dictionariesId:null,

    // 当前登录用户权限code集合
    permissionCodeList:[],
    
}