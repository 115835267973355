import axios from 'axios'; //引入axios
import QS from 'qs'; //引入qs模块，用来序列化post类型的数据
import ipAddress from './ipAddress';
import router from '../router/index';

//请求超过10S则超时
axios.defaults.timeout = 60000;

// 请求拦截器
axios.interceptors.request.use(config => {
  // 每次发送请求之前判断vuex中是否存在token
  // 如果存在，则统一在http请求的header都加上token
  var usr = config.url;
  // console.log(usr)
  // if(usr.search("9000")!= -1){ //内部请求
  if (usr != ipAddress.qi + '/auth/user/login') {
    //过滤登陆接口
    const token = localStorage.getItem("token");
    token && (config.headers['Authorization'] = token);
  }
  // }
  return config;
}, error => {
  return Promise.error(error);
});

//响应拦截器
axios.interceptors.response.use(response => {
  // 接口请求成功，正常拿到数据
  let that = this;
  // console.log(response.data.code)
  // 接口异常
  if (response.data.code != '0000') {
    switch (response.data.code) {
      case '0009':
        router.replace('/Login');
        break;
      default:
        // alert("错误代码:" + response.data.code + "、错误信息:" + response.data.extMsg)
        console.log("接口异常错误代码:" + response.data.code + "、错误信息:" + response.data.desc);
        ElMessage.error("异常！" + response.data.code + " 【" + response.data.desc + "】");
    }
  }
  // 成功
  if (response.data.code == '0000') {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
},
// 响应异常状态
error => {
  if (error.response.status) {
    ElMessage.error('错误, 请求错误...');

    // console.log(error.response)
    // switch (error.response.status) {
    //   default:
    //       alert(error.response.data.message)
    // }
    return Promise.reject(error.response);
  }
});

// 封装get 与 post
let https = {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: params
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.data);
      });
    });
  },
  //
  post(url, params, json) {
    let headers = {};
    let data = params;
    headers['Content-type'] = json ? 'application/json;charset=UTF-8' : 'application/x-www-form-urlencoded;charset=UTF-8';
    data = json ? params : QS.stringify(params);
    return new Promise((resolve, reject) => {
      axios.post(url, params, {
        headers: headers
      }).then(res => {
        // console.log(headers)
        resolve(res.data);
      }).catch(err => {
        console.log(headers);
        reject(err.data);
      });
    });
  },
  postFrom(url, params) {
    let headers = {};
    headers['Content-type'] = 'multipart/form-data';
    // console.log(params)
    return new Promise((resolve, reject) => {
      axios.post(url, params, {
        headers: headers
      }).then(res => {
        // console.log(headers)
        resolve(res.data);
      }).catch(err => {
        console.log(headers);
        reject(err.data);
      });
    });
  },
  postBlob(url, params) {
    let headers = {};
    // let responseType = {};
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // responseType['responseType'] = 'blob';
    // console.log(params)
    return new Promise((resolve, reject) => {
      axios.post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        responseType: 'blob'
      }).then(res => {
        console.log(headers);
        resolve(res.data);
      }).catch(err => {
        console.log(headers);
        reject(err.data);
      });
    });
  }
};
export default https;