import axios from '../../axios/axios';
import ipAddress from '../../axios/ipAddress' //域名
import QS from 'qs'; //序列化


/**
 * 用户相关接口
 */
const user = {

    /**
     * 登陆相关
     */

    //登陆
    login (user) {
        return axios.post(ipAddress.qi + '/auth/user/login', user, true)
    },

    // 选择角色类型
    currentUserAccountInfo () {
        return axios.get(ipAddress.qi + '/user/currentUserAccountInfo', null, false)
    },

    // 获取导航栏
    selectNav (id) {
        return axios.post(ipAddress.qi + '/admin/a1/admin/getResource', QS.stringify({
            id: id
        }), false)
    }
}
export default user;