/**
 *   统一API接口
 */

 //用户模块
 import user from '../api/user/user';
  //首页模块
  import home from '../api/home/home'
 //客户模块
 import customer from '../api/customer/customer';
 //员工模块
 import staff from '../api/staff/staff'
 //项目模块
 import project from '../api/project/project'
 //医院模块
 import hospital from '../api/hospital/hospital'
 //系统设置模块
 import settings from './settings/settings';
// 统计
 import statistics from './statistics/statistics'


//导出接口
export default{
    user,
    home,
    customer,
    staff,
    project,
    hospital,
    settings,
    statistics
 }