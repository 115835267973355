export default{

    // 跟换ID
    updateRecordId(state,value){
        state.recordId = value
    },

    // 更换高级查询ID
    updateAdvancedQueryId(state,value){
        state.advancedQuryId = value
    },

    // 更换字典查询ID
    updateDictionariesId(state,value){
        state.dictionariesId = value
    },

    // 更新当前用户权限code集合
    updatePermissionCodeList(state,value){
        state.permissionCodeList = value
    }

    



}