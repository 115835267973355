import axios from '../../axios/axios';
import ipAddress from '../../axios/ipAddress' //域名
import QS from 'qs'; //序列化


/**
 * 项目相关接口
 */
const project = {

    /**
     * 项目相关
     */

    //开展项目-分页
    findAllByPage (page) {
        return axios.post(ipAddress.qi + '/openProject/findAllByPage', page, true)
    },

    //新增/编辑 开展项目
    save (form) {
        return axios.post(ipAddress.qi + '/openProject/save', form, true)
    },

    // 项目类别分页
    findByPage(page){
        return axios.post(ipAddress.qi + '/projectType/findByPage', page, true)
    },

    // 项目详情
    findById(form){
        return axios.post(ipAddress.qi + '/openProject/findById', form, true)
    },

    // 删除项目
    delById(form){
        return axios.post(ipAddress.qi + '/openProject/delById', form, true)
    }

    



    



}
export default project;