/**
 *   域名管理
 */

const ipAddress = {
    //测试
    // qi:'https://u2561r7537.yicp.fun',
//  qi:'https://132.232.101.55:9600'
 qi:'https://cms.cdbiyehou.com:9600'
//  qi:'http://132.232.101.55:9601'
    // qi:'http://127.0.0.1:9600'
    // qi:'http://localhost:9600'       
}
export default ipAddress;