import axios from '../../axios/axios';
import ipAddress from '../../axios/ipAddress' //域名
import QS from 'qs'; //序列化


/**
 * 客户相关接口
 */
const customer = {

    /**
     * 客户相关
     */

    // 客户分页
    findMyStaffByPage (page) {
        return axios.post(ipAddress.qi + '/customer/findStaffByPage', page, true)
    },

    // 全部客户分页
    findAllByPage (page) {
        return axios.post(ipAddress.qi + '/customer/findAllByPage', page, true)
    },

    // 顶部大搜索
    findByPage(page){
        return axios.post(ipAddress.qi + '/customer/findByPage', page, true)
    },

    // 新增/编辑 客户
    save (form) {
        return axios.post(ipAddress.qi + '/customer/save', form, true)
    },

    // 客户详情-基本信息查询
    findById (form) {
        return axios.post(ipAddress.qi + '/customer/findById', form, true)
    },

    // 客户详情-内部沟通信息查询
    findByCustomer (form) {
        return axios.post(ipAddress.qi + '/customerInteriorInfo/findByCustomer', form, true)
    },

    // 客户详情-内部沟通新增
    saveInteriorInfo (form) {
        return axios.post(ipAddress.qi + '/customerInteriorInfo/save', form, true)
    },


    // 客户详情-派单信息查询
    orderFindByCustomer (form) {
        return axios.post(ipAddress.qi + '/order/findByCustomer', form, true)
    },

    // 客户详情 派单跟进新增
    orderFollowUpSave(form){
        return axios.post(ipAddress.qi + '/orderFollowUp/save', form, true)
    },


    // 客户池-分页
    findPoolByPage (page) {
        return axios.post(ipAddress.qi + '/customer/findPoolByPage', page, true)
    },

    // 客户认领 / 分配
    updateStaff(form){
        return axios.post(ipAddress.qi + '/customer/updateStaff', form, true)
    },

    // 派单 - 我的派单
    findStaffByPage(page){
        return axios.post(ipAddress.qi + '/order/findStaffByPage', page, true)
    },

    // 派单-全部派单
    findAllMyOrderByPage (page) {
        return axios.post(ipAddress.qi + '/order/findAllByPage', page, true)
    },

    // 派单-新增
    orderSave(form){
        return axios.post(ipAddress.qi + '/order/save', form, true)
    },

    // 新-我的成交
    orderDetailFindTradedByPage(page){
        return axios.post(ipAddress.qi + '/orderDetail/findTradedByPage', page, true)
    },

    // 新-全部成交
    orderDetailFindAllTradedByPage(page){
        return axios.post(ipAddress.qi + '/orderDetail/findAllTradedByPage', page, true)
    },

    // 全部成交 - 到账对账
    orderDetailUpdateTradedStatusById(page){
        return axios.post(ipAddress.qi + '/orderDetail/updateTradedStatusById', page, true)
    },


    // 我的成交合计
    countTradedByPage(page){
        return axios.post(ipAddress.qi + '/orderDetail/countTradedByPage', page, true)
    },

    // 全部成交合计
    countAllTradedByPage(page){
        return axios.post(ipAddress.qi + '/orderDetail/countAllTradedByPage', page, true)
    }
    


}
export default customer;