export default{

    // 更新当前用户权限code集合
    updatePermissionCodeList(state,value){
        state.permissionCodes = value
        state.disabledCustomerAdd = !state.permissionCodes.includes('customer_add')
        state.disabledCustomerMod = !state.permissionCodes.includes('customer_mod')
        state.disabledCustomerCommunicateAdd = !state.permissionCodes.includes('customer_communicate_add')
        state.disabledOrderAdd = !state.permissionCodes.includes('order_add')
        state.disabledCustomerClaim = !state.permissionCodes.includes('customer_claim')
        state.disabledCustomerAllot = !state.permissionCodes.includes('customer_allot')
        state.disabledAccounts = state.permissionCodes.includes('order_accounts')
        state.disabledHospitalAdd = !state.permissionCodes.includes('hospital_add')
        state.disabledHospitalDoctorAdd = !state.permissionCodes.includes('hospital_doctor_add')
        state.disabledHospitalProjectAdd = !state.permissionCodes.includes('hospital_project_add')
    },


    // 编辑客户权限
    updateDisabledCustomerMod(state,value){
        state.disabledCustomerMod = value
    },


    // 修改状态
    updateDisabledOrderAdd(state,value){
        state.disabledOrderAdd = value
    },

    // 
    updateDisabledCustomerCommunicateAdd(state,value){
        state.disabledCustomerCommunicateAdd = value
    }



}