import axios from '../../axios/axios';
import ipAddress from '../../axios/ipAddress' //域名
import QS from 'qs'; //序列化


/**
 * 统计相关接口
 */
const statistics = {

    /**
     * 统计相关
     */

    // 渠道统计
    channelCount (form) {
        return axios.post(ipAddress.qi + '/order/channelCount', form, true)
    },

    // 日期统计
    dateCount (form) {
        return axios.post(ipAddress.qi + '/order/dateCount', form, true)
    }



}
export default statistics;