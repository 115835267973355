import axios from '../../axios/axios';
import ipAddress from '../../axios/ipAddress' //域名
import QS from 'qs'; //序列化


/**
 * 员工相关接口
 */
const settings = {

   /**
    * 员工相关
    */

   //高级查询分页
   findAllByPage(page) {
      return axios.post(ipAddress.qi + '/dataHeaderFormat/findAllByPage', page, true)
   },

   // 新增员工 / 修改
   findById(form) {
      return axios.post(ipAddress.qi + '/dataHeaderFormat/findById', form, true)
   },

   // 保存高级查询
   save(form){
      return axios.post(ipAddress.qi + '/dataHeaderFormat/save', form, true)
   },

   // 字典下拉
   findDictByTypeCode(form){
      return axios.post(ipAddress.qi + '/dict/findDictByTypeCode', form, true)
   },

   // 枚举下拉
   findEnumByCode(form){
      return axios.post(ipAddress.qi + '/common/findEnumByCode', form, true)
   },

   // 字典类型分页
   dictTypefindAllByPage(page){
      return axios.post(ipAddress.qi + '/dictType/findAllByPage', page, true)
   },

   // 字典类型新增与编辑
   dictTypeSave(form){
      return axios.post(ipAddress.qi + '/dictType/save', form, true)
   },

   // 类型详情字典分页
   dictfindAllByPage(page){
      return axios.post(ipAddress.qi + '/dict/findAllByPage', page, true)
   },

   // 字典新增与编辑
   dictSave(form){
      return axios.post(ipAddress.qi + '/dict/save', form, true)
   },

   // 删除指定字典
   dictDel(id){
      return axios.get(ipAddress.qi + '/dict/delById/'+id, {})
   },

   // 城市列表
   cityFindAll(form){
      return axios.post(ipAddress.qi + '/city/findAll', form, true)
   },



   /**
    *  角色相关
    * 
    */

   // 角色分页
   roleFindAllByPage(form){
      return axios.post(ipAddress.qi + '/role/findAllByPage', form, true)
   },

   // 角色权限树形结构
   roleFindAllTree(form){
      return axios.post(ipAddress.qi + '/permission/findAllTree', form, true)
   },

   // 角色新增
   roleSave(form){
      return axios.post(ipAddress.qi + '/role/save', form, true)
   },

   // 角色详情
   roleFindById(form){
      return axios.post(ipAddress.qi + '/role/findById', form, true)
   },

   // 删除角色
   roleDelById(form){
      return axios.post(ipAddress.qi + '/role/delById', form, true)
   },


   /**
    *  部门管理
    */

   // 部门树形结构
   departmentFindAllByTree(form){
      return axios.post(ipAddress.qi + '/department/findAllByTree', form, true)
   },

   // 部门新增
   departmentSave(form){
      return axios.post(ipAddress.qi + '/department/save', form, true)
   },

   // 部门删除
   departmentDelById(form){
      return axios.post(ipAddress.qi + '/department/delById', form, true)
   },

   // 部门详情
   departmentFindById(form){
      return axios.post(ipAddress.qi + '/department/findById', form, true)
   },


   /**
    * 图片上传
    */
   uploadImg(form){
      return axios.postFrom(ipAddress.qi + '/fileapi/uploadImg', form, true)
   },

   // 客户图片保存
   customerPhotoAlbumSave(form){
      return axios.post(ipAddress.qi + '/customerPhotoAlbum/save', form, true)
   },

   // 删除图片
   customerPhotoAlbumDelById(form){
      return axios.post(ipAddress.qi + '/customerPhotoAlbum/delById', form, true)
   }

   





}
export default settings;