export default{

    // 跟换ID
    updateCustomerId(state,value){
        state.customerId = value
    },

    



}