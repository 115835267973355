import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    { path: '/Login', component: () => import('../components/login/Login.vue') },
    {
        name: "123",
        path: '/container',
        component: () => import('../components/container/index.vue'),
        children: [
            {
                name: "首页",
                path: '/container/home',
                component: () => import('../components/home/HomeIndex.vue')
            },
            {
                name: "我的客户",
                path: '/container/myCustomer',
                component: () => import('../components/customer/MyCustomer.vue'),
                children: [

                ]
            },
            {
                name: "全部客户",
                path: '/container/customerAll',
                component: () => import('../components/customer/CustomerAll.vue'),
                children: [

                ]
            },
            {
                name: "新增客户",
                path: '/container/myCustomer/addCustomer',
                component: () => import('../components/customer/AddCustomer.vue'),
                children: [

                ]
            },
            {
                name: "客户详情",
                path: '/container/myCustomer/customerInfo',
                component: () => import('../components/customer/CustomerInfo.vue')
            },
            {
                name: "我的派单",
                path: '/container/myDispatchList',
                component: () => import('../components/customer/MyDispatchList.vue')
            },
            {
                name: "我的成交",
                path: '/container/myDeal',
                component: () => import('../components/customer/MyDeal.vue')
            },
            {
                name: "全部成交",
                path: '/container/dealAll',
                component: () => import('../components/customer/DealAll.vue')
            },
            {
                name: "客户池",
                path: '/container/customerPool',
                component: () => import('../components/customer/CustomerPool.vue')
            },
            {
                name: "全部派单",
                path: '/container/dispatchList',
                component: () => import('../components/workBench/dispatch/DispatchList.vue')
            },
            {
                name: "员工管理",
                path: '/container/staff',
                component: () => import('../components/workBench/staff/Staff.vue')
            },
            {
                name: "项目管理",
                path: '/container/project',
                component: () => import('../components/workBench/project/project.vue')
            },
            {
                name: "医院管理",
                path: '/container/hospital',
                component: () => import('../components/workBench/hospital/hospital.vue')
            },
            {
                name: "医院详情",
                path: '/container/hospital/hospitalInfo',
                component: () => import('../components/workBench/hospital/hospitalInfo.vue')
            },
            {
                name: "高级查询",
                path: '/container/advancedQuery',
                component: () => import('../components/settings/advancedQuery.vue')
            },
            {
                name: "高级查询详情",
                path: '/container/advancedQuery/advancedQueryInfo',
                component: () => import('../components/settings/advancedQueryInfo.vue')
            },
            {
                name: "字典管理",
                path: '/container/dictionaries',
                component: () => import('../components/settings/dictionaries.vue')
            },
            {
                name: "字典管理详情",
                path: '/container/dictionaries/dictionariesInfo',
                component: () => import('../components/settings/dictionariesInfo.vue')
            },
            {
                name: "角色管理",
                path: '/container/role',
                component: () => import('../components/settings/Role.vue')
            },
            {
                name: "部门管理",
                path: '/container/department',
                component: () => import('../components/settings/Department.vue')
            },
            {
                name: "渠道统计",
                path: '/container/statistics/channel',
                component: () => import('../components/statistics/channel.vue')
            },
            {
                name: "日期统计",
                path: '/container/statistics/date',
                component: () => import('../components/statistics/date.vue')
            }
        ]
    },

    { path: '/customer/info', component: () => import('../components/customer/CustomerInfo.vue') }


]

const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes: routes // `routes: routes` 的缩写
})

export default router
