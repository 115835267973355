export default{

    // 重置tabs
    resetTabsVuex(state){
        state.editableTabsValue = "首页"
        state.tabsIndex = 1
        state.editableTabs = [{
            title: '首页',
            name: '首页',
            content: '/container/home'
        }]
    },

    // 切换tabs
    updateTabsValue(state,value){
        state.editableTabsValue = value
    },

    // 添加tabs
    pushTabs(state,tab){
        if(state.editableTabs.find(item => item.name == tab.name) == null){
            state.editableTabs.push(tab)    
        }
    },

    // 删除指定tab
    removeTabs(state, name){
        state.editableTabs = state.editableTabs.filter(item=>item.name !== name)
        // state.editableTabsValue = state.editableTabs[state.editableTabs.length-1].name
        // state.editableTabs.splice(index, 1)
    },

    // 更改index
    updateTabIndex(state, index){
        state.tabsIndex = index
    }

    



}