export default {

    // 当前登录用户权限code集合
    permissionCodes:[],

    /**
     * 客户详情
     */

    // 添加客户权限
    disabledCustomerAdd:false,

    // 编辑客户权限
    disabledCustomerMod:false,

    // 是否能新增内部沟通
    disabledCustomerCommunicateAdd:false,

    // 是否能新增派单（含派单内所有）
    disabledOrderAdd:false,

    /**
     * 客户池相关
     */

    // 认领
    disabledCustomerClaim:false,

    // 分配
    disabledCustomerAllot:false,


    /**
     * 全部成交
     */

    // 是否能对订单 进行 到账 和 对账操作
    disabledAccounts:false,


    /**
     * 医院管理相关
     */

    // 医院
    disabledHospitalAdd:false,

    // 医生
    disabledHospitalDoctorAdd:false,

    // 项目
    disabledHospitalProjectAdd:false



    
}